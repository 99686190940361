<template>
  <b-row class="bg-black-0 rounded pr-16 pr-sm-32 mx-0">
    <b-col
      cols="12"
      class="pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden"
    >
      <h2> Screening Antibody - Input</h2>
    </b-col>
    <b-col class="pl-16 pl-sm-32 py-24 py-sm-32">
      <div class="col-12">
        <b-table
            style="overflow: auto; white-space: nowrap;"
            :items="items"
            :fields="fields"
            :busy.sync="isBusy"
            responsive="sm"
        >
          <template #table-busy>
            <div class="hp-p1-body text-center my-2">
              <b-spinner class="align-middle mr-8" />
              <strong>Memuat...</strong>
            </div>
          </template>
        </b-table>
      </div>
      <b-col>
        <div class="d-flex justify-content-end mb-3 ">
            <b-button
                variant="primary-1 "
                type="submit"
                :loading="isBusy"
                @click="storeScreeningAntibody"
            >
              <b-spinner
                small 
                label="Small Spinner" 
                v-if="isBusy"
              />
              <span v-else>Simpan</span>
            </b-button>
        </div>
      </b-col>
    </b-col>
</b-row>
</template>

<script>
import { 
BButton, 
BCol, 
BRow,
BTable,
BSpinner,
BFormSelect,
} 
from 'bootstrap-vue';
import tableDataMixins from '../../../../../mixins/tableDataMixins'
export default {
  name: 'add-nextscreening',
  components: {
    BButton,
    BCol,
    BRow,
    BTable,
    BSpinner,
    BFormSelect,
  },
  data() {
      return {
        items: [
        { 
          barcode:'',
          antibody: '',
        },
        ],
        fields: [
          {
            label: 'Kode Kantong',
            key: 'barcode',
            sortable: true,
          },
          {
            label: 'Antibodi',
            key: 'antibody',
            sortable: true,
          },
        ], 
        screeningData: {
          barcode:'',
          antibody: '',
        },
      }
    },
 mixins: [tableDataMixins],
  computed: {
    keys() {
      return Object.keys(this.items);
    },
    isBusy() {
        return this.$store.state.manageScreeningAntibody.isBusy
    },
  },
  mounted() {
    this.loadFromLocalStorage()
    
    },
  methods: {
     loadFromLocalStorage() {
      const getDataLocal = localStorage.getItem('screeningData')
      const parsData = JSON.parse(getDataLocal)
        if (parsData) {
          this.items[0].barcode = parsData.barcode 
          this.items[0].antibody = this.convertToBoolean( parsData.antibody ) 
        }
    },
      async storeScreeningAntibody() {
        const antibodyValue = this.items[0].antibody === true ? '1' : '0';

        const formData = new FormData();
        formData.append('barcode', this.items[0].barcode);
        formData.append('antibody', antibodyValue);
        
        await this.$store.dispatch('manageScreeningAntibody/storeScreeningAntibody', formData)
          .then(() => {
            this.$router.push({name: 'listscreeningantibodys'})

            this.$bvToast.toast('Data Barcode berhasil ditambahkan', {
              title: 'Success',
              variant: 'success',
              solid: true,
            });
         })
      },
      convertToBoolean(value ) {
        return value === '1' ? 'Positif' : 'Negatif' ;
      },
  }
}
</script>
<style scoped>

</style>